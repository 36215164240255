import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import { useLazyQuery } from '@apollo/client'
import PropTypes from 'prop-types'

import { getWithExpiry, setWithExpiry } from '@/utils/localStorage'
import { GET_USER_BY_ID } from '@/graphql/gql'
import { useCompany } from '@/utils/hooks'

const fetcher = (...args) => fetch(...args).then((res) => res.json())

const useUser = () => {
  const { data, error } = useSWR('/api/auth/me', fetcher)
  return {
    user: data,
    isLoading: !error && !data,
    isError: error,
  }
}

const redirectToLogin = (router) => {
  if (router.pathname !== '/login') router.push('/login')
}

const permissionCheck = ({
  user,
  company,
  module,
  feature,
  region,
  program,
} = {}) => {
  if (user?.superAdmin) {
    return {
      module: 'ADMIN',
      feature: 'ADMIN',
      region: 'ALL',
      program: 'ALL',
      regions: [],
      projects: user?.projects || [],
    }
  }

  const permissionTemplate =
    company &&
    user?.permissionTemplates?.find(
      (template) =>
        template?.company?.id?.toUpperCase() === company.toUpperCase()
    )

  console.log(
    'withIdentity permissionCheck permissionTemplate',
    company,
    user,
    permissionTemplate
  )

  if (!permissionTemplate)
    return {
      module: 'NONE',
      feature: 'NONE',
      region: 'NONE',
      program: 'NONE',
      regions: [],
      projects: [],
    }

  const templateModule = permissionTemplate?.permissions?.modules?.find(
    ({ name }) => name?.toUpperCase() === module?.toUpperCase()
  )
  const permissionModule = module && templateModule

  let permissionFeature =
    feature &&
    templateModule?.features?.find(
      ({ name }) => name.toUpperCase() === feature.toUpperCase()
    )
  if (!permissionFeature) {
    permissionFeature = permissionModule
  }

  const permissionRegion =
    region &&
    user?.permissionTemplates?.find(
      (template) =>
        template?.region?.name?.toUpperCase() === region.toUpperCase()
    )
  const permissionProgram =
    program &&
    permissionRegion?.programs?.find(
      ({ name }) => name?.toUpperCase() === program?.toUpperCase()
    )

  const regions = permissionTemplate?.permissions?.regions

  return {
    module: permissionModule?.permission || 'UNKNOWN',
    feature: permissionFeature?.permission || 'UNKNOWN',
    region: permissionRegion?.permission || 'UNKNOWN',
    program: permissionProgram?.permission || 'UNKNOWN',
    regions: regions || [],
    projects: user?.projects || [],
  }
}

export const IdentityContext = createContext(null)

export const IdentityProvider = ({ children }) => {
  const { user, isLoading, isError } = useUser()
  const { company } = useCompany()

  const [primed, setPrimed] = useState(false)
  const [session, setSession] = useState(null)
  const router = useRouter()
  const [getUserById] = useLazyQuery(GET_USER_BY_ID, {
    onCompleted: ({ getUserById }) => {
      const user = {
        ...getUserById,
        projects: getUserById?.projects?.map(({ project }) => project),
      }
      setWithExpiry(user?.id, user)
      setSession({
        ...user,
        permissions,
      })
      setPrimed(true)
    },
  })

  const permissions = useCallback(
    ({ module, feature, region, projectId, smallProject }) => {
      if (!primed) {
        return { edit: false, show: false, permissions: {} }
      }

      const userPermissions = permissionCheck({
        company,
        user: session,
        module,
        feature,
      })

      const isAdmin = userPermissions?.module === 'ADMIN' || user?.superAdmin

      if (isAdmin) {
        return { edit: true, show: true, permissions: userPermissions }
      } else {
        if (userPermissions?.module === 'NONE') {
          console.log(
            '403 from withIdentity module is NONE',
            user,
            userPermissions
          )
          router.push('/403')
        }

        const userProjects =
          userPermissions?.projects?.filter((p) => p)?.map(({ id }) => id) || []

        const permission = userPermissions?.regions?.find(
          ({ name }) => name === region
        )?.permission

        let hasAccess = false

        switch (permission) {
          case 'NONE':
          case 'ALL':
            hasAccess = true
            break
          case 'ASSIGNED':
            hasAccess = userProjects.includes(projectId)
            break
          case 'SMALL_ASSIGNED':
            hasAccess = userProjects.includes(projectId) || smallProject
            break
          case 'STANDARD_ASSIGNED':
            hasAccess = userProjects.includes(projectId) || !smallProject
            break
          default:
            hasAccess = false
            break
        }

        if (hasAccess)
          return { edit: true, show: true, permissions: userPermissions }

        if (feature) {
          if (
            userPermissions?.feature === 'UNKNOWN' &&
            ['ADMIN', 'WRITE', 'READ'].includes(userPermissions?.module)
          ) {
            return { edit: true, show: true, permissions: userPermissions }
          } else {
            const allowable = ['ADMIN', 'WRITE', 'READ'].includes(
              userPermissions?.feature
            )
            return {
              edit: allowable,
              show: allowable,
              permissions: userPermissions,
            }
          }
        } else {
          return { edit: false, show: false, permissions: userPermissions }
        }
      }
    },
    [company, user, router, primed]
  )

  useEffect(() => {
    if (user) {
      const cachedUser = getWithExpiry(user?.id)
      if (cachedUser) {
        setSession({
          ...user,
          ...cachedUser,
          permissions,
        })
        setPrimed(true)
      } else {
        getUserById({ variables: { id: user?.id } })
      }
    } else if (!isLoading) {
      redirectToLogin(router)
    }
  }, [user, isLoading, isError, router, getUserById, permissions])

  return (
    <IdentityContext.Provider value={session}>
      {children}
    </IdentityContext.Provider>
  )
}

IdentityProvider.propTypes = {
  children: PropTypes.node,
}

export const useIdentity = () => useContext(IdentityContext)
