export const getTheme = (value = '') => {
  const universal = {
    palette: {
      common: {
        white: '#ffffff',
        black: '#000000',
      },
      // main colors
      blue: {
        dark: '#1859B3',
        main: '#3f84e5',
        light: '#93baf0',
        aux: '#293034',
      },
      turquoise: {
        dark: '#2b9388',
        main: '#44c9bc',
        light: '#97e1d9',
      },
      yellow: {
        dark: '#efb507',
        main: '#fad565',
        light: '#fde7a7',
      },
      orange: {
        dark: '#b34d0d',
        main: '#ef7223',
        light: '#f7b085',
      },
      red: {
        dark: '#a22118',
        main: '#df382b',
        light: '#ed8d86',
      },
      purple: {
        dark: '#311955',
        main: '#5b2e9e',
        light: '#a07ad8',
      },
      green: {
        dark: '#005920', // calculated +30% from main
        main: '#07a56c',
        light: '#86FFEB', // calculated -50% from main
      },
      // aux colors
      darkBlue: '#062c4f',
      darkTeal: '#5fa9a2',
      darkYellow: '#ecc245',
      darkOrange: '#ef7223',
      darkRed: '#df382b',
      // state colors
      error: {
        dark: '#a22118',
        main: '#df382b',
        light: '#ed8d86',
      },
      warning: {
        dark: '#b34d0d',
        main: '#ef7223',
        light: '#f7b085',
      },
      info: {
        dark: '#161616', // calculated +30% from main
        main: '#626262',
        light: '#E1E1E1', // calculated -50% from main
      },
      success: {
        dark: '#005920', // calculated +30% from main
        main: '#07a56c',
        light: '#86FFEB', // calculated -50% from main
      },
      // gray colors
      gray: {
        100: '#ededed',
        200: '#d8d8d8',
        300: '#bbbdc0',
        400: '#626262',
        500: '#4d4d4d',
        600: '#333333',
        700: '#252525',
        800: '#1a1a1a',
        900: '#191919',
      },
      text: {
        primary: null,
        secondary: null,
        disabled: null,
        hint: null,
      },
    },
    typography: {
      fontSize: {
        base: '1rem',
        xxs: '1.2rem', // extra extra small
        xs: '1.4rem', // extra small
        md: '1.6rem', // medium
        lg: '1.8rem', // large
        xl: '2rem', // extra large
        xxl: '3.5rem', // extra extra large
      },
      fontFamily: {
        forzaMedium: 'Forza Medium',
        forzaBold: 'Forza Bold',
        interstate: 'Interstate',
        interstateBold: 'Interstate Bold',
        vitesseBook: 'Vitesse Book',
        vitesseMedium: 'Vitesse Medium',
        segoeUi: 'Segoe UI',
      },
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      h1: {
        fontSize: '4.6rem',
      },
      h2: {
        fontSize: '3.6rem',
      },
      h3: {
        fontSize: '3rem',
      },
      h4: {
        fontSize: '2.4rem',
      },
      h5: {
        fontSize: '2rem',
      },
      h6: {
        fontSize: '1.8rem',
      },
    },
    cards: {
      sm: '10', // small
      md: '20', // medium
      lg: '30', // large
      xl: '40', // extra large
    },
  }

  switch (value.toLowerCase()) {
    case 'pointcore':
      return {
        ...universal,
        palette: {
          ...universal.palette,
          primary: { main: '#6ca33c' },
          secondary: { main: '#cee9bf' },
        },
      }
    case 'procedeo':
      return {
        ...universal,
        palette: {
          ...universal.palette,
          primary: { main: '#00395b' },
          secondary: { main: '#fdce07' },
        },
      }
    default:
      return {
        ...universal,
        palette: {
          ...universal.palette,
          primary: { main: '#008348' },
          secondary: { main: '#a5ccb7' },
        },
      }
  }
}

export default getTheme
