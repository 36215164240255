/**
 * @desc Keys that interact with localStorage
 */
export const STORAGE_KEYS = {
  USER_EMAIL: 'USER_EMAIL', // the remembered users email
  REMEMBER_ME: 'REMEMBER_ME', // whether the user wants to be remembered
  AUTH0_STATE: 'AUTH0_STATE', // the temporary auth state when making login request
  IS_EXTERNAL: 'IS_EXTERNAL', // remember whether this user has logged in as external
  IS_EMPLOYEE: 'IS_EMPLOYEE', // remember whether this user has logged in as internal
  COMPANY: 'COMPANY', // remember the currently selected company (pointcore|core|procedeo)
}

/**
 * @description The expiration period for an auth cookie's lifespan.
 */
export const AUTH_EXPIRATION_DURATION = 7

/**
 * @desc Keys that interact with cookies
 */
export const COOKIE_KEYS = {
  TOKEN: 'TOKEN',
}

export const COMPANY_KEYS = {
  POINTCORE: 'POINTCORE',
  CORE: 'CORE',
  PROCEDEO: 'PROCEDEO',
}

export const COMPANIES = Object.keys(COMPANY_KEYS)

// Set company link urls based on VERCEL_ENV (production | preview | development)
// The VERCEL_ENV is undefined locally, however
export const COMPANY_LINKS = () => [
  {
    text: 'Pointcore',
    company: 'pointcore',
    href:
      process.env.VERCEL_ENV === undefined
        ? 'http://opex.pointcoreconstruction.localhost:3000/login'
        : `https://${
            process.env.VERCEL_ENV === 'preview' ? 'dev.' : ''
          }opex.pointcoreconstruction.com/login`,
  },
  {
    text: 'Procedeo',
    company: 'procedeo',
    href:
      process.env.VERCEL_ENV === undefined
        ? 'http://opex.procedeogroup.localhost:3000/login'
        : `https://${
            process.env.VERCEL_ENV === 'preview' ? 'dev.' : ''
          }opex.procedeogroup.com/login`,
  },
  {
    text: 'CORE',
    company: 'core',
    href:
      process.env.VERCEL_ENV === undefined
        ? 'http://opex.coreconstruction.localhost:3000/login'
        : `https://${
            process.env.VERCEL_ENV === 'preview' ? 'dev.' : ''
          }opex.coreconstruction.com/login`,
  },
]

/**
 * @description List of valid design phases for projects in preconstruction
 */
export const PRECON_DESIGN_PHASES = ['PD', 'SD', 'DD', 'CD']

export const PROJECT_PHASES = {
  CORE: [
    // { link: 'Win Sheet', path: '/core/win-sheet' },
    // {
    //   link: 'Precon',
    //   path: '/core/preconstruction',
    // },
    {
      link: 'Business Development',
      path: '/core/business-development',
      name: 'business development',
    },
    {
      link: 'Construction',
      path: '/core/construction',
    },
    {
      link: 'JOC',
      path: '/core/joc',
    },
    // {
    //   link: 'Disaster Services',
    //   path: '/core/disaster-services',
    // },
  ],
  POINTCORE: [
    // {
    //   link: 'Company',
    //   path: '/pointcore',
    // },
    // {
    //   link: 'Win Sheet',
    //   path: '/pointcore/win-sheet',
    // },
    // {
    //   link: 'Strategic Planning',
    //   path: '/pointcore/strategic-planning',
    // },
    {
      link: 'Precon',
      path: '/pointcore/preconstruction',
    },
    {
      link: 'Construction',
      path: '/pointcore/construction',
    },
    // {
    //   link: 'Program Management',
    //   path: '/pointcore/program-management',
    // },
    // {
    //   link: 'Maintenance',
    //   path: '/pointcore/maintenance',
    // },
    // {
    //   link: 'Close Out',
    //   path: '/pointcore/close-out',
    // },
  ],
  PROCEDEO: [
    // { link: 'Win Sheet', path: '/procedeo/win-sheet' },
    // { link: 'Program Management', path: '/procedeo/program-management' },
    // {
    //   link: 'Company',
    //   path: '/procedeo',
    // },
    {
      link: 'FWISD 2017 Bond',
      path: '/procedeo/FWISD%202017',
    },
  ],
}

export const REPORTS_FILTER_OPTS = [
  {
    name: 'AR Aging',
    items: [
      {
        name: 'CORE AZ',
        url: 'https://reports.coreconstruction.com/showreportfromtrustedip.aspx?id=37&P4=1',
      },
      {
        name: 'CORE NV',
        url: 'https://reports.coreconstruction.com/showreportfromtrustedip.aspx?id=37&P4=5',
      },
      {
        name: 'CORE TX',
        url: 'https://reports.coreconstruction.com/showreportfromtrustedip.aspx?id=37&P4=7',
      },
      {
        name: 'CORE LA',
        url: 'https://reports.coreconstruction.com/showreportfromtrustedip.aspx?id=37&P4=3',
      },
      {
        name: 'CORE FL',
        url: 'https://reports.coreconstruction.com/showreportfromtrustedip.aspx?id=37&P4=4',
      },
      {
        name: 'CORE IL',
        url: 'https://reports.coreconstruction.com/showreportfromtrustedip.aspx?id=37&P4=8',
      },
      {
        name: 'CORE IN',
        url: 'https://reports.coreconstruction.com/showreportfromtrustedip.aspx?id=37&P4=6',
      },
    ],
  },
  {
    name: 'DDFD',
    items: [
      {
        name: 'CORE AZ',
        url: 'https://reports.coreconstruction.com/showreportfromtrustedip.aspx?id=38&P3=1',
      },
      {
        name: 'CORE NV',
        url: 'https://reports.coreconstruction.com/showreportfromtrustedip.aspx?id=38&P3=5',
      },
      {
        name: 'CORE TX',
        url: 'https://reports.coreconstruction.com/showreportfromtrustedip.aspx?id=38&P3=7',
      },
      {
        name: 'CORE LA',
        url: 'https://reports.coreconstruction.com/showreportfromtrustedip.aspx?id=38&P3=3',
      },
      {
        name: 'CORE FL',
        url: 'https://reports.coreconstruction.com/showreportfromtrustedip.aspx?id=38&P3=4',
      },
      {
        name: 'CORE IL',
        url: 'https://reports.coreconstruction.com/showreportfromtrustedip.aspx?id=38&P3=8',
      },
      {
        name: 'CORE IN',
        url: 'https://reports.coreconstruction.com/showreportfromtrustedip.aspx?id=38&P3=6',
      },
    ],
  },
]

const permissionTemplateBase = {
  modules: [
    // settings/permissions/templates/new/[companyId] modules
    {
      features: [
        // {
        //   name: 'Photos',
        //   permission: 'NONE',
        // },
      ],
      isDefault: false,
      name: 'Business Development',
      permission: 'NONE',
    },
    {
      features: [
        {
          name: 'Photos',
          permission: 'NONE',
        },
        {
          name: 'Address',
          permission: 'NONE',
        },
        {
          name: 'Team Members',
          permission: 'NONE',
        },
        {
          name: 'Budget',
          permission: 'NONE',
        },
        {
          name: 'Project Information',
          permission: 'NONE',
        },
        {
          name: 'Project Updates',
          permission: 'NONE',
        },
        {
          name: 'Deliverables',
          permission: 'NONE',
        },
        {
          name: 'Milestones',
          permission: 'NONE',
        },
        {
          name: 'Other Resources',
          permission: 'NONE',
        },
        {
          name: 'Estimates',
          permission: 'NONE',
        },
        {
          name: 'Cost Savings',
          permission: 'NONE',
        },
        {
          name: 'Regulatory Documents',
          permission: 'NONE',
        },
        {
          name: 'PreConstruction Process',
          permission: 'NONE',
        },
        {
          name: 'Issues',
          permission: 'NONE',
        },
        {
          name: 'Long Lead Items',
          permission: 'NONE',
        },
        {
          name: 'Trade Partners',
          permission: 'NONE',
        },
        {
          name: 'Unique Feature of Work',
          permission: 'NONE',
        },
      ],
      isDefault: false,
      name: 'Preconstruction',
      permission: 'NONE',
    },
    {
      features: [
        {
          name: 'Project Information',
          permission: 'NONE',
        },
        {
          name: 'Team Members',
          permission: 'NONE',
        },
        {
          name: 'Team Comments',
          permission: 'NONE',
        },
        {
          name: 'Safety',
          permission: 'NONE',
        },
        {
          name: 'Quality',
          permission: 'NONE',
        },
        {
          name: 'Schedule',
          permission: 'NONE',
        },
        {
          name: 'Cost',
          permission: 'NONE',
        },
        {
          name: 'Trade Partners',
          permission: 'NONE',
        },
        {
          name: 'Clients',
          permission: 'NONE',
        },
      ],
      isDefault: false,
      name: 'Construction',
      permission: 'NONE',
    },
  ],
  regions: [],
  // regions: [{ programs: }]
}

const permissionTemplateProcedeo = {
  modules: [
    {
      name: 'Company',
      features: [
        {
          name: 'Project Overview',
          permission: 'NONE',
        },
        {
          name: 'Photos',
          permission: 'NONE',
        },
        {
          name: 'Project Information',
          permission: 'NONE',
        },
        {
          name: 'Team Members',
          permission: 'NONE',
        },
        {
          name: 'Project Billing',
          permission: 'NONE',
        },
        {
          name: 'Project Updates',
          permission: 'NONE',
        },
        {
          name: 'Pre-Design',
          permission: 'NONE',
        },
        {
          name: 'Design',
          permission: 'NONE',
        },
        {
          name: 'Construction',
          permission: 'NONE',
        },
        {
          name: 'Issues',
          permission: 'NONE',
        },
        {
          name: 'Schedule',
          permission: 'NONE',
        },
        {
          name: 'Phasing Plans',
          permission: 'NONE',
        },
        {
          name: 'Partners',
          permission: 'NONE',
        },
        {
          name: 'Procurement',
          permission: 'NONE',
        },
        {
          name: 'Closeout',
          permission: 'NONE',
        },
      ],
      isDefault: false,
      permission: 'NONE',
    },
    {
      name: 'Client',
      features: [
        {
          name: 'Project Overview',
          permission: 'NONE',
        },
        {
          name: 'Photos',
          permission: 'NONE',
        },
        {
          name: 'Project Information',
          permission: 'NONE',
        },
        {
          name: 'Team Members',
          permission: 'NONE',
        },
        {
          name: 'Schedule',
          permission: 'NONE',
        },
      ],
      isDefault: false,
      permission: 'NONE',
    },
  ],
  regions: [],
}

const permissionTemplateBaseCore = { ...permissionTemplateBase }
permissionTemplateBaseCore.modules = [
  ...permissionTemplateBase.modules,
  {
    features: [],
    isDefault: false,
    name: 'JOC',
    permission: 'NONE',
  },
]

export const PERMISSION_TEMPLATE_BASE = permissionTemplateBase

export const PERMISSION_TEMPLATE_BASE_CORE = permissionTemplateBaseCore
export const PERMISSION_TEMPLATE_BASE_PROCEDEO = permissionTemplateProcedeo

export const isSuperAdmin = (permissions) => permissions?.module === 'ADMIN'
